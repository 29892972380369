<template>
    <div class="full-height-layout fill mr-15 ml-15">
        <product-facilities-selector
            v-if="supplierProductImportedDetails"
            :loading="isSavingProduct"
            class="fill mt-10"
            v-model="selectedFacilities"
            :show-production-days-and-quantity-fields="!hasProductionDayAndQuantityMapped"
            ref="facilities"
            auto-select-first
        />

        <div class="align-right">
            <mercur-button
                class="btn btn-yellow"
                type="submit"
                :disabled="isSavingProduct"
                @click="save"
                data-test="supplierImportProductProceed"
            >Save and continue</mercur-button>
        </div>
    </div>
</template>

<script>
import SupplierImportedProductMixin from './SupplierImportedProductMixin'
import ProductFacilitiesSelector from '@/components/products/ProductFacilitiesSelector'

export default {
    name: 'SupplierImportedProductFacilities',
    mixins: [ SupplierImportedProductMixin ],
    components: { ProductFacilitiesSelector },
    data () {
        return {
            selectedFacilities: [],
        }
    },
    watch: {
        supplierProductImportedDetails () {
            if (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.facilities) {
                return
            }

            this.selectedFacilities = this.supplierProductImportedDetails.facilities
            this.initialValues.facilities = JSON.parse(JSON.stringify(this.supplierProductImportedDetails.facilities || []))
        },
    },
    computed: {
        payload () {
            return {
                facilities: this.selectedFacilities,
            }
        },
        hasProductionDayAndQuantityMapped () {
            if (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.columnMapping) {
                return null
            }
            let isProductionDayMapped = false
            let isQuantityMapped = false

            for (let i = 0; i < this.supplierProductImportedDetails.columnMapping.length; i++) {
                const mappedTo = Object.keys(this.supplierProductImportedDetails.columnMapping[i].mappedTo)

                if (mappedTo.includes('PRODUCTION_DAY')) {
                    isProductionDayMapped = true
                }

                if (mappedTo.includes('QUANTITY')) {
                    isQuantityMapped = true
                }
                if (isQuantityMapped && isProductionDayMapped) {
                    return true
                }
            }

            return isQuantityMapped && isProductionDayMapped
        },
    },
    methods: {
        save () {
            const verification = this.$refs.facilities.checkVerification()
            if (verification.$invalid) {
                this.$root.$emit('notification:global', {
                    message: `Something is missing in your data.`,
                    type: 'error',
                })
                return
            }

            this.$emit('save', {
                ...this.payload,
                onSuccess: () => {
                    this.skipCheckForChanges = true
                    this.$emit('pushRoute', { name: 'SupplierImportedProductPackages' })
                } })
        },
        checkForChanges () {
            if (!this.skipCheckForChanges && JSON.stringify(this.initialValues.facilities) !== JSON.stringify(this.selectedFacilities)) {
                return true
            }
            return false
        },
    },
    created () {
        if (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.facilities) {
            return
        }

        this.selectedFacilities = this.supplierProductImportedDetails.facilities
        this.initialValues.facilities = JSON.parse(JSON.stringify(this.supplierProductImportedDetails.facilities || []))
    },
}
</script>
